import React from 'react';
import { graphql } from 'gatsby';
import { StaticQuery } from "gatsby";
// import { graphql } from 'react-apollo';
// import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ObjectCard from '../components/ObjectCard';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const CARDS_PER_PAGE = 6;

const Investigations = () => (
  <StaticQuery
    query={query}
    render={data => (
        <Layout>

          <SEO 
            title="Investigations"
            description="Investigations into spooky places, stories, and people."
          />

          <Typography variant="h2">
            Investigations
          </Typography>
          <section>
              <Grid container spacing={2}>
                  {data.gcms.investigations.map(investigation => (
                    <ObjectCard 
                      key={investigation.id}
                      title={investigation.title}
                      link={`/investigation/${investigation.slug}`}
                      image={investigation.image}
                      relatedPlaceCount = {investigation.places.length}
                      relatedPersonCount = {investigation.people.length}
                      relatedLoreCount = {investigation.lores.length}
                      relatedBookCount = {0}
                      relatedInvestigationCount = {0}
                      relatedTourCompanyCount = {0}
                    />
                  ))}
              </Grid>

              
          </section>
        </Layout>
    )}
  />
);

const query = graphql`
  query {
    gcms {
      investigations(orderBy: publishedAt_DESC) {
        id
        title
        slug
        image {
          handle
          title
        }
        people {id}
        places {id}
        lores {id}
      }
      investigationsConnection {
        aggregate {
          count
        }
      }
    }
  }
`;

  
export default Investigations;


// const Investigations = ({
//   data: { loading, error, investigations, investigationsConnection, networkStatus },
//   loadMoreCards,
// }) => {
//   if (error) return <h1>Error fetching investigations!</h1>;
//   if (investigations && investigationsConnection) {
//     const areMorePosts = investigations.length < investigationsConnection.aggregate.count;
//     return (
//       <React.Fragment>
//         <Typography variant="h2">
//           Investigations
//         </Typography>
//         <section>
//             <Grid container spacing={2}>
//                 {investigations.map(investigation => (
//                   <ObjectCard 
//                     title={investigation.title}
//                     link={`/investigation/${investigation.slug}`}
//                     image={investigation.image}
//                     relatedPlaceCount = {investigation.places.length}
//                     relatedPersonCount = {investigation.people.length}
//                     relatedLoreCount = {investigation.lores.length}
//                     relatedBookCount = {0}
//                     relatedInvestigationCount = {0}
//                     relatedTourCompanyCount = {0}
//                   />
//                 ))}
//             </Grid>

//             <Grid container justify="center" style={{marginTop: '16px'}}>
//               <Grid item >
//                 {areMorePosts ? (
//                   <Button 
//                     variant="contained" 
//                     color="primary"
//                     disabled={loading}
//                     onClick={() => loadMoreCards()}
//                   >
//                     {loading ? 'Loading...' : 'Show More Investigations'}
//                   </Button>
//                 ) : (
//                   ''
//                 )}
//               </Grid>
//             </Grid>
//         </section>
//       </React.Fragment>
//     );
//   }
//   return <h2>Loading investigations...</h2>;
// };

// export const query = gql`
// query investigations($first: Int!, $skip: Int!) {
//   investigations(orderBy: publishedAt_DESC, first: $first, skip: $skip) {
//     id
//     title
//     slug
//     image {
//       handle
//       title
//     }
//     people {id}
//     places {id}
//     lores {id}
//   }
//   investigationsConnection {
//     aggregate {
//       count
//     }
//   }
// }
// `;

// export const queryVars = {
// skip: 0,
// first: CARDS_PER_PAGE,
// };

// export default graphql(query, {
// options: {
//   variables: queryVars,
// },
// props: ({ data }) => ({
//   data,
//   loadMoreCards: () => {
//     return data.fetchMore({
//       variables: {
//         skip: data.investigations.length,
//       },
//       updateQuery: (previousResult, { fetchMoreResult }) => {
//         if (!fetchMoreResult) {
//           return previousResult;
//         }
//         return Object.assign({}, previousResult, {
//           investigations: [...previousResult.investigations, ...fetchMoreResult.investigations],
//         });
//       },
//     });
//   },
// }),
// })(Investigations);